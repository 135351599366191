@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
    html {
        font-family: 'Titillium Web';
    }
    body {
      background-color: #15151E;
      letter-spacing: 0.2px;
      overflow-y: scroll;
    }
}

@font-face {
  font-family: 'Formula';
  font-style: bold;
  font-weight: 600;
  src: url('/fonts/Formula.ttf');
}